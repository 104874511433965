import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import React, { useCallback, useEffect, useState } from 'react';
import { buildService } from '../service';
const api = buildService('/claim_groups/{id}/project_overview_import', {
  only: ['get']
}, {
  only: ['postFormData']
});
const projectImportService = {
  useImportStatus(claimGroupId, getData) {
    const [importStatus, setImportStatus] = useState({});
    const [intervalId, setIntervalId] = useState(null);
    const getImportStatus = useErrorHandling(useCallback(async () => {
      const response = await projectImportService.get(claimGroupId);
      if (response === null) {
        setImportStatus({
          import_status: 'never_imported'
        });
      } else {
        setImportStatus({
          ...response
        });
      }
      if ((response === null || response === void 0 ? void 0 : response.import_status) !== 'pending') {
        await getData();
      }
      return response;
    }, [getData, claimGroupId]));
    useEffect(() => {
      if (importStatus.import_status === 'pending' && !intervalId) {
        const currentId = setInterval(() => {
          getImportStatus();
        }, 2000);
        setIntervalId(currentId);
      } else if (importStatus.import_status !== 'pending' && !!intervalId) {
        clearInterval(intervalId);
        setIntervalId(null);
      }
      return () => {
        clearInterval(intervalId);
      };
    }, [getImportStatus, importStatus.import_status, intervalId, claimGroupId]);
    useEffect(() => {
      /** Please dont ever do it like I did it here. This is a quickfix to fix a bug in production before a call with a client. */
      if (claimGroupId && process.env.PUBLIC_URL === '/be') {
        getImportStatus();
      }
    }, [claimGroupId, getImportStatus]);
    return [importStatus, setImportStatus];
  },
  get(claimGroupId) {
    return api.get(claimGroupId);
  },
  useImportProjects(claimGroupId, setImportStatus) {
    const importProjects = useErrorHandling(useCallback(async body => {
      const response = await api.postFormData(claimGroupId, body);
      setImportStatus({
        ...response
      });
    }, [setImportStatus, claimGroupId]));
    return importProjects;
  }
};
projectImportService.useCrud = (claimGroupId, getData) => {
  const [importStatus, setImportStatus] = projectImportService.useImportStatus(claimGroupId, getData);
  const importProjects = projectImportService.useImportProjects(claimGroupId, setImportStatus);
  return {
    importStatus,
    importProjects
  };
};
export default projectImportService;