import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';

const proejctTemplateImportService = {
  create(claimGroupId, formData, type) {
    return fetch(`/api/claim_groups/${claimGroupId}/project_template_imports?type=${type}`, {
      ...request.post,
      headers: {
        'Accept': 'application/json, text/plain, */*'
      },
      body: formData,
    });
  },
}

export default proejctTemplateImportService;