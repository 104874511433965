import React, { useEffect, useState } from 'react';
import { FINANCIAL_SECTION_GENERAL_LINK, SIDEBAR_MENU_ITEMS, SIDEBAR_MENU_ITEMS_CUSTOMER } from '@rd-web-markets/market/dist/constants';
import SidebarPortal from '@rd-web-markets/shared/dist/util/SidebarPortal';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { SHOULD_SHOW_ADD_CLAIM_BUTTON_IN_CLAIM_GROUP_SIDEBAR } from '@rd-web-markets/market/dist/constants';
import { useSelector } from 'react-redux';
import claimProjectReportsListsService from '@services/lists/claim_groups/claim_project_reports_lists.service';

const ClaimGroupSidebar = ({ claimGroup, highlightedMenuItem, showMenuItems = true }) => {
  const { claim_group_step: claimGroupStep } = useParams();
  const user = useSelector((state) => state.auth.user);
  
  const [projectsForSidebar, setProjectsForSidebar] = useState([])

  useEffect(() => {
    const fetchProjectsForSidebar = async () => {
      const allProjects = (await claimProjectReportsListsService.base.all(claimGroup.id, {})).claim_project_reports
      const sortedProjects = allProjects.sort((a, b) => a.report_order - b.report_order)

      const sortedProjectsForSidebar = sortedProjects.map(report => {
        return { text: report.project_name, link: `claim_groups/${claimGroup.id}/technical_proof/project_reports/${report.id}` }
      })

      setProjectsForSidebar([...sortedProjectsForSidebar])
    }

    if (claimGroup.projects_count <= 20) {
      fetchProjectsForSidebar()
    } else {
      setProjectsForSidebar([])
    }
  }, [claimGroup.id, claimGroup.projects_count])

  const getSubMenuItems = (menu) => {
    if (menu.link === 'claim_costs') {
      const subMenuItems = claimGroup.claims.map(claim => {
        return { text: menu.showLastFourChars ? claim.name.slice(-4) : claim.name, link: `claims/${claim.id}/financial_details/${FINANCIAL_SECTION_GENERAL_LINK}` }
      })

      if (SHOULD_SHOW_ADD_CLAIM_BUTTON_IN_CLAIM_GROUP_SIDEBAR) {
        subMenuItems.push({ text: 'Add Claim', link: `add_claim/company/${claimGroup.company.id_in_provider_system}/claim_groups/${claimGroup.id}` })
      }

      if(menu.showWages) {
        subMenuItems.unshift(
          { text: 'All Base', link: `companies/${claimGroup.company_id}/${claimGroup.id}/base/wages` },
          { text: 'All Claims', link: `companies/${claimGroup.company_id}/${claimGroup.id}/claims/wages` },
          { text: 'All Claims and Base', link: `companies/${claimGroup.company_id}/${claimGroup.id}/all/wages` }
        )
      }
      return subMenuItems
    } else if (menu.link === 'technical_section') {
      return projectsForSidebar
    }
  }

  const getLink = (menuItem) => {
    if (menuItem.link === 'edit/general') {
      return `claim_groups/${claimGroup.id}/${menuItem.link}`
    } else if (menuItem.link === 'technical_section') {
      return `claim_groups/${claimGroup.id}/technical_proof/company_info`
    } else if (menuItem.link === 'project_overview') {
      return `claim_groups/${claimGroup.id}/project_overview`
    } else if (menuItem.link === 'dashboard') {
      const company = claimGroup.company
      return company.dashboard_type === 'business_unit_intensity_breakdown' 
        ? `regular_realtime_dashboard/${company.id}` 
        : `dashboard/${company.id}`
    } else if (menuItem.link === 'data_centre'){
      return `claim_groups/${claimGroup.id}/${menuItem.link}/main_page`
     }else if (menuItem.link === 'claim_group_dashboard') {
        return `claim_groups/${claimGroup.id}/${menuItem.link}`
    } else {
      return `companies/${claimGroup.company_id}/${claimGroup.id}/${menuItem.link}`
    }
  }

  const items = user.account_type === 'customer' ? SIDEBAR_MENU_ITEMS_CUSTOMER : SIDEBAR_MENU_ITEMS
  const headerItem = user.account_type === 'customer' ? { link: 'companies', text: claimGroup.name} : { link: `companies/${claimGroup.company_id}/master`, text: claimGroup.name }

  const menuItems = claimGroup && items.map(item => {
    let subMenuItems = item.subMenu ? getSubMenuItems(item) : false
    if (!subMenuItems.length) {
      subMenuItems = false
    }

    return { 
      text: item.text,
      highlighted: item.link === highlightedMenuItem,
      link: getLink(item),
      subMenu: subMenuItems
    }
  })

  const claimCostItem = [{ 
    text: `Company Cost ${claimGroup.name.slice(-5)}`,
    highlighted: true,
    link: `companies/${claimGroup.company_id}/${claimGroup.id}/claim_costs`,
    subMenu: false
  }]
  
  return (
    <SidebarPortal 
    menuItems={(claimGroupStep === 'claim_costs' && !showMenuItems) ? claimCostItem : menuItems} 
    headerItem={headerItem} 
    />
  )
}

export default ClaimGroupSidebar
