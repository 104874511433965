import { patchDocument, PatchType, TextRun } from 'docx';
import * as QueryString from 'query-string';
import ProjectQuestionnaireTemplateService from '../project_questionnaire_template.service';
import convertHTMLToPlainText from '@rd-web-markets/shared/dist/util/helpers/convertHTMLToPlainText';
const getDocumentName = questionnaire_type => {
  switch (questionnaire_type) {
    case 'new_it_project_document':
      return 'New IT Project Questionnaire.docx';
    case 'new_non_it_project_document':
      return 'New Non-IT Project Questionnaire.docx';
    case 'ongoing_it_project_document':
      return 'Ongoing IT Project Questionnaire.docx';
    case 'ongoing_non_it_project_document':
      return 'Ongoing Non-IT Project Questionnaire.docx';
    default:
      return 'Project Questionnaire.docx';
  }
};
const ONGOING_QUESTIONNAIRE_TYPES = ['ongoing_non_it_project_document', 'ongoing_it_project_document'];
const getPatcherObject = projectDataField => {
  return {
    type: PatchType.PARAGRAPH,
    children: [new TextRun(projectDataField)]
  };
};
const getNewDocumentPatches = projectData => {
  const leadConsultant = projectData.consultant_roles.find(role => role.role === 'claim_group_access_lead_consultant');
  const companyName = projectData.company.abbreviated_name;
  return {
    lead_consultant: getPatcherObject(leadConsultant.user ? leadConsultant.user.name : ''),
    company_name: getPatcherObject(companyName)
  };
};
const getOngoingDocumentPatches = (projectData, questionnaire_type) => {
  if (questionnaire_type === 'ongoing_it_project_document') {
    return {
      lead_consultant: getPatcherObject(projectData.leadConsultant),
      company_name: getPatcherObject(projectData.companyName),
      project_title: getPatcherObject(projectData.project_name),
      project_identifier: getPatcherObject(projectData.code || ''),
      project_start_date: getPatcherObject(projectData.start_date),
      project_end_date: getPatcherObject(projectData.end_date),
      project_objectives: getPatcherObject(convertHTMLToPlainText(projectData.it_context_objectives)),
      project_changes_done_on_an_existing_solution: getPatcherObject(convertHTMLToPlainText(projectData.it_context_changes)),
      project_phases: getPatcherObject(convertHTMLToPlainText(projectData.it_technical_aspects_project_phases)),
      project_major_technical_barriers: getPatcherObject(convertHTMLToPlainText(projectData.it_technical_aspects_major_workers_faced)),
      project_contain_any_form_of_development: getPatcherObject(convertHTMLToPlainText(projectData.it_technical_aspects_any_form_of_development)),
      project_resulted_in_the_development: getPatcherObject(convertHTMLToPlainText(projectData.it_results_resulted_in_commercialization))
    };
  } else {
    return {
      lead_consultant: getPatcherObject(projectData.leadConsultant),
      company_name: getPatcherObject(projectData.companyName),
      project_title: getPatcherObject(projectData.project_name),
      project_identifier: getPatcherObject(projectData.code || ''),
      project_start_date: getPatcherObject(projectData.start_date),
      project_end_date: getPatcherObject(projectData.end_date),
      non_it_context_in_company: getPatcherObject(convertHTMLToPlainText(projectData.non_it_context_in_company)),
      non_it_context_scientific_advances: getPatcherObject(convertHTMLToPlainText(projectData.non_it_context_scientific_advances)),
      non_it_context_phases: getPatcherObject(convertHTMLToPlainText(projectData.non_it_context_phases)),
      non_it_technical_aspects_types_of_rnd_activities: getPatcherObject(convertHTMLToPlainText(projectData.non_it_technical_aspects_types_of_rnd_activities)),
      non_it_technical_aspects_major_technical_uncertanties: getPatcherObject(convertHTMLToPlainText(projectData.non_it_technical_aspects_major_technical_uncertanties)),
      non_it_results_lead_to_marketing_of_product: getPatcherObject(convertHTMLToPlainText(projectData.non_it_results_lead_to_marketing_of_product)),
      non_it_results_knowledge: getPatcherObject(convertHTMLToPlainText(projectData.non_it_results_knowledge))
    };
  }
};
export const generateProjectQuestionnaire = async (projectData, questionnaire_type) => {
  const {
    download
  } = ProjectQuestionnaireTemplateService;
  download(QueryString.stringify({
    questionnaire_type
  })).then(blob => {
    const reader = new FileReader();
    reader.onload = function (event) {
      const arrayBufferData = event.target.result;
      patchDocument(arrayBufferData, {
        outputType: "nodebuffer",
        patches: ONGOING_QUESTIONNAIRE_TYPES.includes(questionnaire_type) ? getOngoingDocumentPatches(projectData, questionnaire_type) : getNewDocumentPatches(projectData)
      }).then(finaldata => {
        const blob = new Blob([finaldata], {
          type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        });
        // download the file
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = getDocumentName(questionnaire_type);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      });
    };
    reader.readAsArrayBuffer(blob);
  }).catch(error => {
    console.error('Error fetching template.docx:', error);
  });
};